import React from 'react'
import { MasterLayout } from '../../components/layout'
import { InputForm } from '../../components/ReceiveDocument'
import {
  DigitizingList,
  DigitizingItem,
  EvnDocumentList,
  EvnDocumentItem,
  LoadingItem,
  DetailFileContent,
} from '../../components/common'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button } from '@blueprintjs/core'
import * as Actions from '../../actions/'
import * as Type from '../../constants/Api'
import * as Tool from '../../helpers/'
import * as MESSAGER from '../../constants/MessageForm'
import { Intent } from '@blueprintjs/core'
import FileDownload from 'file-saver'
import { checkDonVi } from '../../helpers/Helper'

const UNITID = null
const ISALL = false
const IS_HAS_CURRENT_UNIT = false
const INPUT_RECEIVE_DOCUMENT = true

class InputReceiveDocumentPage extends React.Component {
  static propTypes = {
    objects: PropTypes.array.isRequired,
    securities: PropTypes.array.isRequired,
    priorities: PropTypes.array.isRequired,
    saveCodes: PropTypes.array.isRequired,
    relativeUnits: PropTypes.array.isRequired,
    leaderDepartments: PropTypes.array.isRequired,
    digitalDocuments: PropTypes.object.isRequired,
  }

  refHandlers = {
    toaster: ref => (this.toaster = ref),
    detailFileContent: ref => (this.detailFileContent = ref),
  }

  constructor(props, context) {
    super(props, context)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)
    this.handleDigitalChange = this.handleDigitalChange.bind(this)
    this.handleShowDetailDigital = this.handleShowDetailDigital.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickCancel = this.handleClickCancel.bind(this)
    this.handleEvnChange = this.handleEvnChange.bind(this)
    this.handleChangeFile = this.handleChangeFile.bind(this)
    this.downloadFile = this.downloadFile.bind(this)
    this.handleShowDetailEvn = this.handleShowDetailEvn.bind(this)
    this.handleClickInitial = this.handleClickInitial.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.handleLoadSubmit = this.handleLoadSubmit.bind(this)

    this.state = {
      isShow: false,
      isShowDetail: false,
      isShowButton: true,
      digitalDocumentId: null,
      evnDocumentId: null,
      fileEvnId: null,
      itemId: null,
      receiveDocument: null,
      digitalDocuments: [],
      evnDocuments: [],
      dataList: [],
      status: null,
      receiveDocumentId: '',
      isUpdate: false,
      soLuuId: null,
      fileDefault: {
        fileName: '20171005083456899_9a217faa-b823-4290-bce6-779ee83f2c2d.docx',
      },
      selectedFile: null,
      selectedFileId: '',
      selectedFileName: '',
      listFile: [],
      isLoadingData: {
        soLuuId: true,
        // doKhanId: true,
        // doBaoMatId: true,
        // doiTuongCongVanId: true,
        coQuanBanHanhId: true,
        // trinhLanhDaoId: true,
        digital: true,
        receiveDocument: true,
        evnDocuments: false,
      },
      selectedFileURL: null,
      parentUnitId: this.props.auth ? this.props.auth.parentUnitId : null,
      isFromEvnDocument: false,
      isLoadingFile: false,
      isFailLoadingFile: false,
      isLoadingFileEvn: true,
      isNotPDF: false,
      editFile: null,
      isShowUpdate: false,
      isSubmitSuccess: false,
      isLoadedData: {
        digital: false,
        evnDocuments: false,
      },
      isLoadingSubmit: false,
    }
  }

  handleLoadSubmit = () => {
    this.setState({
      isLoadingSubmit: !this.state.isLoadingSubmit,
    })
  }

  handleDigitalChange = async (isShowData = null, e) => {
    if (this.state.isLoadedData && this.state.isLoadedData.digital === false) {
      await this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          digital: true,
        },
      })
    }

    let isShow = !this.state.isShow
    let isShowDetail = this.state.isShowDetail
    if (typeof isShowData === 'boolean') {
      isShow = isShowData
      isShowDetail = false
    }

    if (this.state.isLoadedData && this.state.isLoadedData.digital === false) {
      return this.props.actions
        .receiveDocumentFetchDigitalDocuments(Type.DIGITAL_RECEIVE_DOCUMENT)
        .then(res => {
          let digitalDocuments = []
          if (
            res.payload &&
            res.payload.data &&
            res.payload.data.entities &&
            res.payload.data.entities.digitalDocuments
          ) {
            digitalDocuments = res.payload.data.entities.digitalDocuments
          }
          this.setState({ digitalDocuments })
        })
        .then(() => {
          this.setState({
            isLoadingData: {
              soLuuId: false,
              doKhanId: false,
              doBaoMatId: false,
              doiTuongCongVanId: false,
              coQuanBanHanhId: false,
              trinhLanhDaoId: false,
              digital: false,
              evnDocuments: false,
            },
            isShow,
            isShowDetail,
            dataList:
              this.props.digitalDocuments && this.props.digitalDocuments.list,
            status: 'digital',
            isLoadedData: {
              ...this.state.isLoadedData,
              digital: true,
            },
          })
        })
        .catch(e => {
          this.setState({
            isLoadingData: {
              soLuuId: false,
              doKhanId: false,
              doBaoMatId: false,
              doiTuongCongVanId: false,
              coQuanBanHanhId: false,
              trinhLanhDaoId: false,
              digital: false,
              evnDocuments: false,
            },
            isShow,
            isShowDetail,
            dataList:
              this.props.digitalDocuments && this.props.digitalDocuments.list,
            status: 'digital',
          })
        })
    }

    return this.setState({
      isLoadingData: {
        soLuuId: false,
        doKhanId: false,
        doBaoMatId: false,
        doiTuongCongVanId: false,
        coQuanBanHanhId: false,
        trinhLanhDaoId: false,
        digital: false,
        evnDocuments: false,
      },
      isShow,
      isShowDetail,
      dataList: this.props.digitalDocuments && this.props.digitalDocuments.list,
      status: 'digital',
    })
  }

  handleEvnChange = async (isShowData = null, e) => {
    if (
      this.state.isLoadedData &&
      this.state.isLoadedData.evnDocuments === false
    ) {
      await this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          evnDocuments: true,
        },
      })
    }

    let isShow = !this.state.isShow
    let isShowDetail = this.state.isShowDetail
    if (typeof isShowData === 'boolean') {
      isShow = isShowData
      isShowDetail = false
    }

    if (
      this.state.isLoadedData &&
      this.state.isLoadedData.evnDocuments === false
    ) {
      return this.props.actions
        .evnDocumentsFetchList()
        .then(res => {
          let evnDocuments = []
          if (
            res.payload &&
            res.payload.data &&
            res.payload.data.entities &&
            res.payload.data.entities.evnDocuments
          ) {
            evnDocuments = res.payload.data.entities.evnDocuments
          }
          this.setState({ evnDocuments })
        })
        .then(() => {
          this.setState({
            isLoadingData: {
              soLuuId: false,
              doKhanId: false,
              doBaoMatId: false,
              doiTuongCongVanId: false,
              coQuanBanHanhId: false,
              trinhLanhDaoId: false,
              digital: false,
              evnDocuments: false,
            },
            isShow,
            isShowDetail,
            dataList: this.props.evnDocuments && this.props.evnDocuments.list,
            status: 'evnDocuments',
            isLoadedData: {
              ...this.state.isLoadedData,
              evnDocuments: true,
            },
          })
        })
        .catch(e => {
          this.setState({
            isLoadingData: {
              soLuuId: false,
              doKhanId: false,
              doBaoMatId: false,
              doiTuongCongVanId: false,
              coQuanBanHanhId: false,
              trinhLanhDaoId: false,
              digital: false,
              evnDocuments: false,
            },
            isShow,
            isShowDetail,
            dataList: this.props.evnDocuments && this.props.evnDocuments.list,
            status: 'evnDocuments',
          })
        })
    }

    return this.setState({
      isLoadingData: {
        soLuuId: false,
        doKhanId: false,
        doBaoMatId: false,
        doiTuongCongVanId: false,
        coQuanBanHanhId: false,
        trinhLanhDaoId: false,
        digital: false,
        evnDocuments: false,
      },
      isShow,
      isShowDetail,
      dataList: this.props.evnDocuments && this.props.evnDocuments.list,
      status: 'evnDocuments',
    })
  }

  handleChangeFile(e) {
    if (
      e.target.getAttribute('file') !== this.state.selectedFileId.toString()
    ) {
      let selectedFileId = e.target.getAttribute('file')
      let selectedFileName = this.state.listFile.find(
        item => item.fileId.toString() === selectedFileId
      ).tenFile
      let id = this.state.evnDocumentId
      let selectedFile = null
      let isNotPDF = false
      this.setState({
        selectedFileName,
        selectedFileId,
        isLoadingFileEvn: true,
        isNotPDF,
      })
      this.props.actions
        .evnDocumentsFetchFileItem(id, selectedFileId)
        .then(res => {
          if (res && res.payload && res.payload.data) {
            let objectUrl = URL.createObjectURL(res.payload.data)
            selectedFile = Tool.Utils.blobToFile(
              res.payload.data,
              selectedFileName
            )
            let fileType = selectedFileName.slice(-3)
            let selectedFileURL = objectUrl
            if (fileType.toUpperCase() !== 'PDF') {
              FileDownload.saveAs(res.payload.data, selectedFileName)
              selectedFileURL = ''
              isNotPDF = true
            }
            this.setState({
              selectedFile,
              selectedFileURL,
              isNotPDF,
              isLoadingFileEvn: false,
            })
          }
        })
    }
  }

  downloadFile(e) {
    e.preventDefault()
    FileDownload.saveAs(this.state.selectedFile, this.state.selectedFileName)
  }

  handleClickGoBack(e) {
    this.setState({
      isLoadingData: {
        soLuuId: false,
        doKhanId: false,
        doBaoMatId: false,
        doiTuongCongVanId: false,
        coQuanBanHanhId: false,
        trinhLanhDaoId: false,
        digital: false,
        evnDocuments: false,
      },
      isShowDetail: false,
      selectedFileURL: null,
    })
  }

  handleClickInitial(e) {
    this.setState({
      isLoadingData: {
        soLuuId: false,
        doKhanId: false,
        doBaoMatId: false,
        doiTuongCongVanId: false,
        coQuanBanHanhId: false,
        trinhLanhDaoId: false,
        digital: false,
        evnDocuments: false,
      },
      isShowDetail: false,
      isShow: false,
      selectedFileURL: null,
      status: null,
    })
  }

  handleShowDetailDigital = (digitalDocumentId, fileName) => {
    let selectedFile = null
    this.setState({ isLoadingFile: true })
    if (fileName && fileName.trim().length > 0) {
      this.props.actions
        .digitalDocumentsFetchItem(digitalDocumentId)
        .then(res => {
          if (res && res.payload && res.payload.data) {
            let objectUrl = URL.createObjectURL(res.payload.data)
            selectedFile = Tool.Utils.blobToFile(res.payload.data, fileName)
            this.setState({
              selectedFile,
              selectedFileURL: objectUrl,
              isLoadingFile: false,
            })
          } else {
            this.setState({ isLoadingFile: false, isFailLoadingFile: true })
          }
        })
        .catch(e => {
          this.setState({ isLoadingFile: false, isFailLoadingFile: true })
        })
    }
    this.setState({
      isLoadingData: {
        soLuuId: false,
        doKhanId: false,
        doBaoMatId: false,
        doiTuongCongVanId: false,
        coQuanBanHanhId: false,
        trinhLanhDaoId: false,
        digital: false,
        evnDocuments: false,
      },
      isShowDetail: !this.state.isShowDetail,
      digitalDocumentId: digitalDocumentId,
      itemId: digitalDocumentId,
      evnDocumentId: null,
    })
  }

  handleShowDetailEvn = params => {
    if (!params) {
      return
    }
    this.setState({
      isLoadingFile: true,
      isLoadingFileEvn: true,
      isNotPDF: false,
    })
    let listFile = []
    let selectedFileId = ''
    let selectedFile = null
    let isNotPDF = false
    let selectedFileURL = ''
    let selectedFileName = ''
    if (params.fileName && params.fileName.trim().length > 0) {
      this.props.actions.evnDocumentsFetchFileList(params.id).then(res => {
        if (res && res.payload && res.payload.data) {
          listFile = res.payload.data.result.items
          selectedFileId = params.fileId
          selectedFileName = params.fileName
          this.setState({
            selectedFileId,
            selectedFileName,
            listFile,
          })
        }
      })
      this.props.actions
        .evnDocumentsFetchFileItem(params.id, params.fileId)
        .then(res => {
          if (res && res.payload && res.payload.data) {
            let objectUrl = URL.createObjectURL(res.payload.data)
            selectedFile = Tool.Utils.blobToFile(
              res.payload.data,
              params.fileName
            )
            let fileType = params.fileName.slice(-3)
            selectedFileURL = objectUrl
            if (fileType.toUpperCase() !== 'PDF') {
              FileDownload.saveAs(res.payload.data, params.fileName)
              selectedFileURL = ''
              isNotPDF = true
            }
            this.setState({
              selectedFile,
              selectedFileURL,
              isLoadingFile: false,
              isLoadingFileEvn: false,
              isNotPDF,
            })
          } else {
            this.setState({
              isLoadingFile: false,
              isFailLoadingFile: true,
              isLoadingFileEvn: false,
            })
          }
        })
        .catch(e => {
          this.setState({
            isLoadingFile: false,
            isFailLoadingFile: true,
            isLoadingFileEvn: false,
          })
        })
    }
    this.setState({
      isLoadingData: {
        soLuuId: false,
        doKhanId: false,
        doBaoMatId: false,
        doiTuongCongVanId: false,
        coQuanBanHanhId: false,
        trinhLanhDaoId: false,
        digital: false,
        evnDocuments: false,
      },
      isShowDetail: !this.state.isShowDetail,
      evnDocumentId: params.id,
      fileEvnId: params.fileId,
      itemId: params.id,
      digitalDocumentId: null,
    })
  }

  handleSubmit = async (msg, intent, isSuccess, soLuuId) => {
    if (isSuccess && isSuccess === true) {
      if (soLuuId) {
        Tool.Utils.saveData('soLuuId-congVanDen', soLuuId)
      }
      if (this.state.isUpdate) {
        this.setState({ receiveDocument: null })
        this.props.actions.commonAddToasterMessage({
          message: msg,
          intent: intent,
        })
        this.props.actions.refreshPage(true)
        return this.props.history.goBack()
      }

      // CHANGED: Tao thanh cong thi reset form
      let isLoadingData = {
        ...this.state.isLoadingData,
        soLuuId: true,
      }

      if (
        this.state.digitalDocumentId &&
        this.state.status === 'digital' &&
        !this.state.evnDocumentId
      ) {
        isLoadingData = {
          ...this.state.isLoadingData,
          digital: true,
        }
      }

      if (
        !this.state.digitalDocumentId &&
        this.state.status === 'evnDocuments' &&
        this.state.evnDocumentId
      ) {
        isLoadingData = {
          ...this.state.isLoadingData,
          evnDocuments: true,
        }
      }

      await this.setState({
        receiveDocument: null,
        isSubmitSuccess: true,
        isShowDetail: false,
        isLoadingData,
        isLoadingSubmit: true,
      })

      // CHANGED: show message
      await this.props.actions.commonAddToasterMessage({
        message: msg,
        intent: intent,
      })

      // CHANGED: Goi lai api get soLuu
      await this.props.actions
        .commonFetchSaveCodes(Type.PAGE, UNITID, ISALL, Type.SAVECODE_RECEIVE)
        .then(() => {
          let soLuuId = Tool.Utils.getData('soLuuId-congVanDen')

          if (this.props.saveCodes) {
            if (
              this.props.saveCodes.find(item => item.soLuuId === soLuuId) ===
              undefined
            ) {
              soLuuId = null
            }
          }
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              soLuuId: false,
            },
            soLuuId,
          })
        })

      if (
        this.state.digitalDocumentId &&
        this.state.status === 'digital' &&
        !this.state.evnDocumentId
      ) {
        // CHANGED: Load lai danh sach so hoa
        await this.props.actions
          .receiveDocumentFetchDigitalDocuments(Type.DIGITAL_RECEIVE_DOCUMENT)
          .then(res => {
            let digitalDocuments = []
            let dataList = []
            if (
              res.payload &&
              res.payload.data &&
              res.payload.data.entities &&
              res.payload.data.entities.digitalDocuments
            ) {
              digitalDocuments = res.payload.data.entities.digitalDocuments
            }
            if (
              this.props.digitalDocuments &&
              this.props.digitalDocuments.list
            ) {
              dataList = this.props.digitalDocuments.list
            }
            this.setState({ digitalDocuments, dataList })
          })
          .finally(() => {
            this.setState({
              isLoadingData: {
                ...this.state.isLoadingData,
                digital: false,
              },
            })
          })
      }

      if (
        !this.state.digitalDocumentId &&
        this.state.status === 'evnDocuments' &&
        this.state.evnDocumentId
      ) {
        // CHANGED: Load lai danh sach evn
        await this.props.actions
          .evnDocumentsFetchList()
          .then(res => {
            let evnDocuments = []
            let dataList = []
            if (
              res.payload &&
              res.payload.data &&
              res.payload.data.entities &&
              res.payload.data.entities.evnDocuments
            ) {
              evnDocuments = res.payload.data.entities.evnDocuments
            }
            if (this.props.evnDocuments && this.props.evnDocuments.list) {
              dataList = this.props.evnDocuments.list
            }
            this.setState({ evnDocuments, dataList })
          })
          .finally(() => {
            this.setState({
              isLoadingData: {
                ...this.state.isLoadingData,
                evnDocuments: false,
              },
            })
          })
      }
    } else {
      await this.props.actions.commonAddToasterMessage({
        message: msg,
        intent: intent,
      })
    }
    this.setState({ isLoadingSubmit: false, isSubmitSuccess: false })
  }

  handleClickCancel = () => {
    if (
      this.state.isFromEvnDocument ||
      this.props.match.path === '/cong-van/cong-van-den/chinh-sua/:id'
    ) {
      return this.props.history.goBack()
    }

    return this.props.history.push(
      '/cong-van/cong-van-den/danh-sach/chua-xu-ly'
    )
  }

  handleClickSelect = digitalDocumentId => {
    this.setState({
      isLoadingData: {
        soLuuId: false,
        doKhanId: false,
        doBaoMatId: false,
        doiTuongCongVanId: false,
        coQuanBanHanhId: false,
        trinhLanhDaoId: false,
        digital: false,
      },
      digitalDocumentId: digitalDocumentId,
      isShow: false,
    })
  }

  componentWillMount() {
    if (this.props.match.path === '/cong-van/cong-van-den/chinh-sua/:id') {
      this.setState({
        isShowButton: false,
        receiveDocumentId: this.props.match.params.id,
        isUpdate: true,
        isShow: false,
        isLoadingData: {
          ...this.state.isLoadingData,
          digital: false,
        },
      })
    } else {
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          receiveDocument: false,
        },
      })
    }
  }

  async componentDidMount() {
    // await this.props.actions.commonFetchObjects().then(() => {
    //   this.setState({
    //     isLoadingData: {
    //       ...this.state.isLoadingData,
    //       doiTuongCongVanId: false
    //     }
    //   });
    // });

    // await  this.props.actions.commonFetchSecurities().then(() => {
    //   this.setState({
    //     isLoadingData: {
    //       ...this.state.isLoadingData,
    //       doBaoMatId: false
    //     }
    //   });
    // });

    // await this.props.actions.commonFetchPriorities().then(() => {
    //   this.setState({
    //     isLoadingData: {
    //       ...this.state.isLoadingData,
    //       doKhanId: false
    //     }
    //   });
    // });

    if (!this.props.objects) {
      this.props.actions.commonFetchObjects()
    }

    if (!this.props.securities) {
      this.props.actions.commonFetchSecurities()
    }

    if (!this.props.priorities) {
      this.props.actions.commonFetchPriorities()
    }

    await this.props.actions
      .commonFetchSaveCodes(Type.PAGE, UNITID, ISALL, Type.SAVECODE_RECEIVE)
      .then(() => {
        let soLuuId = Tool.Utils.getData('soLuuId-congVanDen')

        if (this.props.saveCodes) {
          if (
            this.props.saveCodes.find(item => item.soLuuId === soLuuId) ===
            undefined
          ) {
            soLuuId = null
          }
        }
        this.setState({
          isLoadingData: {
            ...this.state.isLoadingData,
            soLuuId: false,
          },
          soLuuId,
        })
      })

    await this.props.actions
      .commonFetchUnitsRelative(
        this.props.auth.mainUnitId,
        IS_HAS_CURRENT_UNIT,
        INPUT_RECEIVE_DOCUMENT
      )
      .then(() => {
        this.setState({
          isLoadingData: {
            ...this.state.isLoadingData,
            coQuanBanHanhId: false,
          },
        })
      })

    // await this.props.actions.commonFetchDepartmentsLeader().then(() => {
    //   this.setState({
    //     isLoadingData: {
    //       ...this.state.isLoadingData,
    //       trinhLanhDaoId: false
    //     }
    //   });
    // });

    if (!this.state.isUpdate) {
      await this.props.actions
        .receiveDocumentFetchDigitalDocuments(Type.DIGITAL_RECEIVE_DOCUMENT)
        .then(res => {
          let digitalDocuments = []
          let dataList = []
          if (
            res.payload &&
            res.payload.data &&
            res.payload.data.entities &&
            res.payload.data.entities.digitalDocuments
          ) {
            digitalDocuments = res.payload.data.entities.digitalDocuments
          }
          if (this.props.digitalDocuments && this.props.digitalDocuments.list) {
            dataList = this.props.digitalDocuments.list
          }
          this.setState({ digitalDocuments, dataList })
        })
        .then(() => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              digital: false,
            },
            isLoadedData: {
              ...this.state.isLoadedData,
              digital: true,
            },
          })
        })
    }

    if (
      this.state.isLoadingData &&
      Object.values(this.state.isLoadingData).find(item => item === true) ===
        undefined &&
      this.props.location &&
      this.props.location.state
    ) {
      await this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          evnDocuments: true,
        },
      })
      await this.props.actions
        .evnDocumentsFetchList()
        .then(res => {
          let evnDocuments = []
          if (
            res.payload &&
            res.payload.data &&
            res.payload.data.entities &&
            res.payload.data.entities.evnDocuments
          ) {
            evnDocuments = res.payload.data.entities.evnDocuments
          }
          this.setState({ evnDocuments })
        })
        .then(() => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              evnDocuments: false,
            },
            isLoadedData: {
              ...this.state.isLoadedData,
              evnDocuments: true,
            },
          })
        })
        .catch(e => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              evnDocuments: false,
            },
            isLoadedData: {
              ...this.state.isLoadedData,
              evnDocuments: true,
            },
          })
        })
      await this.setState(
        {
          dataList: this.props.evnDocuments && this.props.evnDocuments.list,
          status: 'evnDocuments',
          evnDocumentId: this.props.location.state.id,
          fileEvnId: this.props.location.state.fileId,
          itemId: this.props.location.state.id,
          digitalDocumentId: null,
          isShowDetail: true,
          isFromEvnDocument: true,
        },
        () => {
          let listFile = []
          let selectedFileId = ''
          let selectedFileName = ''
          let selectedFile = null
          let isNotPDF = false
          let selectedFileURL = ''
          const params = this.props.location.state
          if (params.fileName && params.fileName.trim().length > 0) {
            this.props.actions
              .evnDocumentsFetchFileList(params.id)
              .then(res => {
                if (res && res.payload && res.payload.data) {
                  listFile = res.payload.data.result.items
                  selectedFileId = params.fileId
                  selectedFileName = params.fileName
                  this.setState({
                    selectedFileName,
                    selectedFileId,
                    listFile,
                  })
                }
              })
            this.props.actions
              .evnDocumentsFetchFileItem(params.id, params.fileId)
              .then(res => {
                if (res && res.payload && res.payload.data) {
                  let objectUrl = URL.createObjectURL(res.payload.data)
                  selectedFile = Tool.Utils.blobToFile(
                    res.payload.data,
                    params.fileName
                  )
                  let fileType = params.fileName.slice(-3)
                  selectedFileURL = objectUrl
                  if (fileType.toUpperCase() !== 'PDF') {
                    FileDownload.saveAs(res.payload.data, params.fileName)
                    selectedFileURL = ''
                    isNotPDF = true
                  }
                  this.setState({
                    selectedFile,
                    selectedFileURL,
                    isLoadingFileEvn: false,
                    isNotPDF,
                  })
                }
              })
          }
        }
      )
    }

    if (this.state.receiveDocumentId) {
      // CHANGED: Đổi api get chi tiết công văn
      this.props.actions
        .getReceiveDocumentItemInformation(this.state.receiveDocumentId)
        .then(() => {
          let state = this.state
          if (
            this.props.receiveDocument &&
            this.props.receiveDocument.information
          ) {
            state.receiveDocument = this.props.receiveDocument.information
            state.editFile = this.props.receiveDocument.information.fileNoiDung
            state.isLoadingData.receiveDocument = false
          }
          this.setState(state)
        })
    }
  }

  componentWillUnmount() {
    this.setState({ digitalDocuments: [] })
  }

  onDocumentComplete = pages => {
    this.setState({ page: 1, pages })
  }

  onPageComplete = page => {
    this.setState({ page })
  }

  handlePrevious = () => {
    this.setState({ page: this.state.page - 1 })
  }

  handleNext = () => {
    this.setState({ page: this.state.page + 1 })
  }

  renderPagination = (page, pages) => {
    let previousButton = (
      <li className="btn-pager previous" onClick={this.handlePrevious}>
        <span className="click-renderPagination pt-icon pt-icon-circle-arrow-left"></span>
      </li>
    )
    if (page === 1) {
      previousButton = (
        <li className="btn-pager previous disabled">
          <span className="click-renderPagination pt-icon pt-icon-circle-arrow-left"></span>
        </li>
      )
    }
    let nextButton = (
      <li className="btn-pager next" onClick={this.handleNext}>
        <span className="click-renderPagination pt-icon pt-icon-circle-arrow-right"></span>
      </li>
    )
    if (page === pages) {
      nextButton = (
        <li className="btn-pager next disabled">
          <span className="click-renderPagination pt-icon pt-icon-circle-arrow-right"></span>
        </li>
      )
    }
    return (
      <nav>
        <ul className="pager paper-viewfile">
          {previousButton}
          {nextButton}
        </ul>
      </nav>
    )
  }

  handleSubmitRemoveItem(id) {
    if (id) {
      this.setState(
        {
          isLoadingData: {
            ...this.state.isLoadingData,
            digital: true,
          },
        },
        () => {
          this.props.actions.deleteDigitizingItem(id).then(res => {
            if (res.payload && res.payload.data.result) {
              this.props.actions
                .receiveDocumentFetchDigitalDocuments(
                  Type.DIGITAL_RECEIVE_DOCUMENT
                )
                .then(res => {
                  let digitalDocuments = []
                  let dataList = []
                  if (
                    res.payload &&
                    res.payload.data &&
                    res.payload.data.entities &&
                    res.payload.data.entities.digitalDocuments
                  ) {
                    digitalDocuments =
                      res.payload.data.entities.digitalDocuments
                  }
                  if (
                    this.props.digitalDocuments &&
                    this.props.digitalDocuments.list
                  ) {
                    dataList = this.props.digitalDocuments.list
                  }
                  this.setState({ digitalDocuments, dataList })
                })
                .then(() => {
                  this.setState({
                    isLoadingData: {
                      ...this.state.isLoadingData,
                      digital: false,
                    },
                  })
                })
              return this.props.actions.commonAddToasterMessage({
                message: MESSAGER.TOATS_MESSAGE_DELETE_SUCCESS,
                intent: Intent.SUCCESS,
              })
            }
            this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_DELETE_FAIL,
              intent: Intent.DANGER,
            })
            this.setState({
              isLoadingData: {
                ...this.state.isLoadingData,
                digital: false,
              },
            })
          })
        }
      )
    }
  }

  render() {
    return (
      <MasterLayout typeSidebar="documentary" collapseSideBar={true}>
        <div className="form-input-receive-document">
          <div
            className={classnames({
              'detail-receive-document':
                this.state.isShowDetail || this.state.isUpdate,
            })}
          >
            {(this.state.isShow || this.state.isUpdate) && (
              <div className="heading-page">
                <div className="row">
                  <div className="col-xs-12">
                    <h3 className="text-heading-page">
                      <span className="icon-Nhap_cong_van_den icon-heading"></span>
                      <span className="text-heading">
                        {this.state.isUpdate
                          ? 'Sửa công văn đến'
                          : 'Nhập công văn đến'}
                      </span>
                    </h3>
                    {(!this.state.isLoadingData ||
                      Object.values(this.state.isLoadingData).find(
                        item => item === true
                      ) === undefined) &&
                      this.state.isShow &&
                      this.state.receiveDocumentId.trim().length === 0 && (
                        <div className="bottom-add-receive-document pull-right box-button-shortcuts">
                          <div className="group-button-head">
                            <Button
                              type="submit"
                              onClick={this.handleClickInitial}
                              disabled={
                                (this.state.isLoadingData &&
                                  this.state.isLoadingData.digital === true) ||
                                this.state.isLoadingSubmit === true
                              }
                              className="btn-digitizing-cvd"
                            >
                              Nhập công văn đến
                            </Button>
                            {this.state.isShowButton &&
                              this.state.status === 'evnDocuments' &&
                              Type.WEB_NAME !== 'Darsitec' && (
                                <Button
                                  type="submit"
                                  onClick={this.handleDigitalChange.bind(
                                    this,
                                    true
                                  )}
                                  disabled={
                                    (this.state.isLoadingData &&
                                      this.state.isLoadingData.digital ===
                                        true) ||
                                    this.state.isLoadingSubmit === true
                                  }
                                  className="btn-digitizing-cvd"
                                >
                                  Nhập từ số hoá
                                </Button>
                              )}

                            {this.state.isShowButton &&
                              this.state.parentUnitId === null &&
                              this.state.status === 'digital' &&
                              checkDonVi(
                                this.props.auth.user.dsChucDanh,
                                'EVN Global'
                              ) && (
                                <Button
                                  type="submit"
                                  onClick={this.handleEvnChange.bind(
                                    this,
                                    true
                                  )}
                                  disabled={
                                    (this.state.isLoadingData &&
                                      this.state.isLoadingData.evnDocuments ===
                                        true) ||
                                    this.state.isLoadingSubmit === true
                                  }
                                  className="btn-digitizing-cvd"
                                >
                                  Nhập từ EVN
                                </Button>
                              )}
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            )}

            {!this.state.isShowDetail && !this.state.isUpdate && (
              <div
                className={classnames('row', {
                  'center-xs': !this.state.isShow && !this.state.isUpdate,
                  'format-center': !this.state.isShow && !this.state.isUpdate,
                })}
              >
                <div
                  className={classnames({
                    'col-md-6 col-xs-12 col-sm-12 padding-0':
                      (this.state.isShow || this.state.isUpdate) &&
                      !this.state.isShowDetail,
                    'col-md-8 col-xs-12 col-sm-12 padding-0 form-width-90':
                      !this.state.isShow && !this.state.isShowDetail,
                  })}
                >
                  {!this.state.isShow && !this.state.isUpdate && (
                    <div className="heading-page bottom-add-receive-document">
                      <h3 className="pull-left text-heading-page">
                        <span className="icon-Nhap_cong_van_den icon-heading"></span>
                        <span className="text-heading">Nhập công văn đến</span>
                      </h3>
                      <div className="pull-right group-button-head">
                        {this.state.isShowButton &&
                          Type.WEB_NAME !== 'Darsitec' && (
                            <Button
                              type="submit"
                              onClick={this.handleDigitalChange}
                              disabled={
                                (this.state.isLoadingData &&
                                  this.state.isLoadingData.digital === true) ||
                                this.state.isLoadingSubmit === true
                              }
                              className="btn-digitizing-cvd"
                            >
                              Nhập từ số hoá
                            </Button>
                          )}

                        {this.state.isShowButton &&
                          this.state.parentUnitId === null &&
                          checkDonVi(
                            this.props.auth.user.dsChucDanh,
                            'EVN CPC'
                          ) && (
                            <Button
                              type="submit"
                              onClick={this.handleEvnChange}
                              disabled={
                                (this.state.isLoadingData &&
                                  this.state.isLoadingData.evnDocuments ===
                                    true) ||
                                this.state.isLoadingSubmit === true
                              }
                              className="btn-digitizing-cvd"
                            >
                              Nhập từ EVN
                            </Button>
                          )}
                      </div>
                    </div>
                  )}

                  <InputForm
                    isShow={this.state.isShow}
                    objects={this.props.objects}
                    saveCodes={this.props.saveCodes}
                    priorities={this.props.priorities}
                    securities={this.props.securities}
                    relativeUnits={this.props.relativeUnits}
                    leaderDepartments={this.props.leaderDepartments}
                    actions={this.props.actions}
                    onSubmit={this.handleSubmit}
                    onLoadSubmit={this.handleLoadSubmit}
                    digitalDocumentId={this.state.digitalDocumentId}
                    digitalDocuments={this.state.digitalDocuments}
                    evnDocumentId={this.state.evnDocumentId}
                    evnDocuments={this.state.evnDocuments}
                    receiveDocument={this.state.receiveDocument}
                    isUpdate={this.state.isUpdate}
                    donViId={this.props.auth.mainUnitId}
                    soLuuId={this.state.soLuuId}
                    isLoadingData={this.state.isLoadingData}
                    selectedFile={this.state.selectedFile}
                    onClickCancle={this.handleClickCancel}
                    readOnly={this.props.readOnly}
                    isSubmitSuccess={this.state.isSubmitSuccess}
                    auth={this.props.auth}
                  />
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12">
                  {this.state.isLoadingData &&
                    Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) !== undefined &&
                    (!this.state.receiveDocumentId ||
                      this.state.receiveDocumentId.trim().length === 0) &&
                    this.state.status && (
                      <div className="loading-container">
                        <LoadingItem />
                        <LoadingItem />
                        <LoadingItem />
                        <LoadingItem />
                        <LoadingItem />
                      </div>
                    )}
                  {(!this.state.isLoadingData ||
                    Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) === undefined) &&
                    this.state.isShow &&
                    this.state.receiveDocumentId.trim().length === 0 &&
                    this.state.status === 'digital' && (
                      <DigitizingList
                        sizeHeight={545}
                        list={this.state.dataList}
                        onClickHidden={this.handleClickInitial}
                        onClickShowDetail={this.handleShowDetailDigital}
                        onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                      />
                    )}
                  {(!this.state.isLoadingData ||
                    Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) === undefined) &&
                    this.state.isShow &&
                    this.state.receiveDocumentId.trim().length === 0 &&
                    this.state.status === 'evnDocuments' && (
                      <EvnDocumentList
                        list={this.state.dataList}
                        onClickHidden={this.handleClickInitial}
                        onClickShowDetail={this.handleShowDetailEvn}
                      />
                    )}
                </div>
              </div>
            )}

            {(this.state.isShowDetail || this.state.isUpdate) && (
              <div className="detail-container">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12 padding-0">
                    {this.state.isUpdate &&
                      this.state.isLoadingData &&
                      this.state.editFile &&
                      !Object.values(this.state.isLoadingData).find(
                        item => item === true
                      ) && (
                        <span
                          className="btn-view-file-detail"
                          onClick={() => {
                            this.detailFileContent.handleFullScreen(true)
                          }}
                        >
                          <span className="left-icon icon-Xem_thuc_hien"></span>
                          <span className="text-content">
                            Xem chi tiết công văn
                          </span>
                        </span>
                      )}
                  </div>
                  <div
                    className={classnames('box-left', {
                      'box-left-showdetail':
                        this.state.isShowDetail || this.state.isUpdate,
                      'full-form-editInputReceiveDocumentPage':
                        this.state.isUpdate,
                    })}
                  >
                    <div className="pd-none">
                      <InputForm
                        isShow={this.state.isShow}
                        objects={this.props.objects}
                        saveCodes={this.props.saveCodes}
                        priorities={this.props.priorities}
                        securities={this.props.securities}
                        relativeUnits={this.props.relativeUnits}
                        leaderDepartments={this.props.leaderDepartments}
                        actions={this.props.actions}
                        onSubmit={this.handleSubmit}
                        onLoadSubmit={this.handleLoadSubmit}
                        digitalDocumentId={this.state.digitalDocumentId}
                        digitalDocuments={this.state.digitalDocuments}
                        evnDocumentId={this.state.evnDocumentId}
                        evnDocuments={this.state.evnDocuments}
                        receiveDocument={this.state.receiveDocument}
                        isUpdate={this.state.isUpdate}
                        donViId={this.props.auth.mainUnitId}
                        soLuuId={this.state.soLuuId}
                        isLoadingData={this.state.isLoadingData}
                        selectedFile={this.state.selectedFile}
                        onClickCancle={this.handleClickCancel}
                        readOnly={this.props.readOnly}
                        fileEvnId={this.state.fileEvnId}
                        isSubmitSuccess={this.state.isSubmitSuccess}
                        auth={this.props.auth}
                      />
                    </div>
                  </div>
                  <div
                    className={classnames('box-right', {
                      'box-right-showdetail':
                        this.state.isShowDetail || this.state.isUpdate,
                    })}
                  >
                    <div className="viewfile-digitizing-container">
                      <div
                        className={classnames('container-form-action', {
                          'form-edit-ReceiveDocument': this.state.isUpdate,
                        })}
                      >
                        {this.state.isShowDetail && (
                          <div className="heading-goback">
                            <p
                              className="left-heading-goback"
                              onClick={this.handleClickGoBack}
                            >
                              <span className="pt-icon pt-icon-arrow-left"></span>
                              <span className="text-back">
                                Trở lại danh sách
                              </span>
                            </p>
                          </div>
                        )}
                        {/* View file khi chinh sua */}
                        {this.state.isUpdate &&
                          this.state.isLoadingData &&
                          window.innerWidth > 1023 &&
                          Object.values(this.state.isLoadingData).find(
                            item => item === true
                          ) !== undefined && (
                            <div className="loading-container">
                              <LoadingItem />
                              <LoadingItem />
                              <LoadingItem />
                              <LoadingItem />
                              <LoadingItem />
                            </div>
                          )}
                        {this.state.editFile && this.state.isUpdate && (
                          <DetailFileContent
                            file={this.state.editFile}
                            actions={this.props.actions}
                            ref={this.refHandlers.detailFileContent}
                            auth={this.props.auth}
                          />
                        )}

                        {/* View file so hoa / EVN */}
                        {this.state.isLoadingFile && (
                          <div className="loader-line"></div>
                        )}
                        {!this.state.isLoadingFile &&
                          !this.state.isFailLoadingFile &&
                          this.state.selectedFileURL &&
                          this.state.itemId &&
                          this.state.itemId.toString().length > 0 && (
                            <div className="iframe-container">
                              <div className="embed-container">
                                <iframe
                                  frameBorder="0"
                                  scrolling="no"
                                  src={this.state.selectedFileURL}
                                  title="File"
                                  allowFullScreen
                                  type="application/pdf"
                                />
                              </div>
                            </div>
                          )}
                        {!this.state.isLoadingFile &&
                          this.state.isFailLoadingFile &&
                          this.state.itemId &&
                          this.state.itemId.toString().length > 0 && (
                            <div
                              className="cpc-error"
                              style={{ height: '300px' }}
                            >
                              <div className="cpc-text">
                                Có lỗi: không thể tải tài liệu.
                              </div>
                            </div>
                          )}

                        {this.state.isShowDetail && (
                          <div className="list-digitizing detail-digitizing">
                            {this.state.status === 'digital' &&
                              this.state.digitalDocumentId &&
                              this.state.digitalDocumentId.toString().length >
                                0 && (
                                <DigitizingItem
                                  item={
                                    this.state.digitalDocuments[
                                      this.state.digitalDocumentId
                                    ]
                                  }
                                  onClickShowDetail={
                                    this.handleShowDetailDigital
                                  }
                                  onHandleSubmitRemoveItem={
                                    this.handleSubmitRemoveItem
                                  }
                                />
                              )}
                            {this.state.status === 'evnDocuments' &&
                              this.state.evnDocumentId &&
                              this.state.evnDocumentId.toString().length >
                                0 && (
                                <EvnDocumentItem
                                  item={
                                    this.state.evnDocuments[
                                      this.state.evnDocumentId
                                    ]
                                  }
                                  onClickShowDetail={this.handleShowDetailEvn}
                                />
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  objects: state.common.objects,

  securities: state.common.securities,

  priorities: state.common.priorities,

  saveCodes: state.common.saveCodes,

  relativeUnits: state.common.relativeUnits,

  leaderDepartments: state.common.leaderDepartments,

  readOnly: state.common.enableReadOnly,

  digitalDocuments: {
    ...state.digitalDocuments,
    list: state.digitalDocuments.list.items.map(
      id => state.entities.digitalDocuments[id]
    ),
  },

  receiveDocument: {
    ...state.receiveDocuments,
    information: state.receiveDocuments.information,
  },

  evnDocuments: {
    ...state.evnDocuments,
    list:
      state.evnDocuments.list &&
      state.evnDocuments.list.items.map(id => state.entities.evnDocuments[id]),
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputReceiveDocumentPage)
