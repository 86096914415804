import React, { Component } from 'react'
import { Button, Collapse } from '@blueprintjs/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import * as Tool from '../../../../helpers/'

class ListFilterAssigned extends Component {
  static propTypes = {
    status: PropTypes.array.isRequired,
    staffs: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
  }

  state = {
    dateUpdate: moment(),
    isOpen: false,
    workExpiringSoon: 0,
    workExpired: 0,
    filter: {
      quaHan: false,
      ganQuaHan: false,
      textSearch: '',
      ngayGiaoViec: {
        from: moment().subtract(1, 'months'),
        to: moment(),
      },
      hanGiaiQuyet: {
        from: '',
        to: '',
      },
      trangThai: undefined,
      nguoiDuocGiaoId: undefined,
    },
    isActions: {
      quaHan: undefined,
      ganQuaHan: undefined,
    },
    dateFromToString: moment().subtract(1, 'months').format('DD/MM/YYYY'),
    dateToToString: moment().format('DD/MM/YYYY'),
    dealineFromToString: '',
    dealineToToString: '',
  }

  componentWillReceiveProps(props) {
    const { filter, workExpiringSoon, workExpired } = props

    if (props) {
      this.setState({
        workExpiringSoon: workExpiringSoon || 0,
        workExpired: workExpired || 0,
      })
    }

    if (filter) {
      const { ngayGiaoViec, hanGiaiQuyet } = filter
      this.setState({
        filter: {
          quaHan: filter.quaHan || false,
          ganQuaHan: filter.ganQuaHan || false,
          textSearch: filter.textSearch || '',
          ngayGiaoViec: {
            from:
              ngayGiaoViec &&
              ngayGiaoViec.from &&
              ngayGiaoViec.from.trim().length > 0
                ? moment(ngayGiaoViec.from)
                : '',
            to:
              ngayGiaoViec &&
              ngayGiaoViec.to &&
              ngayGiaoViec.to.trim().length > 0
                ? moment(ngayGiaoViec.to)
                : '',
          },
          hanGiaiQuyet: {
            from:
              hanGiaiQuyet &&
              hanGiaiQuyet.from &&
              hanGiaiQuyet.from.trim().length > 0
                ? moment(hanGiaiQuyet.from)
                : '',
            to:
              hanGiaiQuyet &&
              hanGiaiQuyet.to &&
              hanGiaiQuyet.to.trim().length > 0
                ? moment(hanGiaiQuyet.to)
                : '',
          },
          trangThai: filter.trangThai || '',
          nguoiDuocGiaoId: filter.nguoiDuocGiaoId || '',
        },
        dateFromToString:
          ngayGiaoViec &&
          ngayGiaoViec.from &&
          ngayGiaoViec.from.trim().length > 0
            ? moment(ngayGiaoViec.from)
            : '',
        dateToToString:
          ngayGiaoViec && ngayGiaoViec.to && ngayGiaoViec.to.trim().length > 0
            ? moment(ngayGiaoViec.to)
            : '',
        dealineFromToString:
          hanGiaiQuyet &&
          hanGiaiQuyet.from &&
          hanGiaiQuyet.from.trim().length > 0
            ? moment(hanGiaiQuyet.from)
            : '',
        dealineToToString:
          hanGiaiQuyet && hanGiaiQuyet.to && hanGiaiQuyet.to.trim().length > 0
            ? moment(hanGiaiQuyet.to)
            : '',
      })
    }
  }

  clearFilter(e) {
    const filter = {
      quaHan: false,
      ganQuaHan: false,
      textSearch: '',
      ngayGiaoViec: {
        from: moment().subtract(1, 'months'),
        to: moment(),
      },
      hanGiaiQuyet: {
        from: '',
        to: '',
      },
      trangThai: '',
      nguoiDuocGiaoId: '',
      isActions: {
        quaHan: undefined,
        ganQuaHan: undefined,
      },
    }
    this.setState(
      {
        filter,
        dateFromToString: moment().subtract(1, 'months').format('DD/MM/YYYY'),
        dateToToString: moment().format('DD/MM/YYYY'),
        dealineFromToString: '',
        dealineToToString: '',
      },
      () => {
        this.handleSubmit()
        this.props.actions.getNumberWorkPage()
      }
    )
  }

  handleDateChange(name, pos, nameToString, e) {
    let value = ''
    if (e) {
      value = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: e ? e : undefined,
        },
      },
      [nameToString]: value,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, pos, nameToString, value) {
    if (
      pos === 'from' &&
      !this.state.filter[name].to &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      pos === 'from' &&
      this.state.filter[name].to &&
      Tool.Utils.isValidDate(value, {
        before: {
          date: this.state.filter[name].to,
        },
      })
    ) {
      return
    }

    if (
      pos === 'to' &&
      !this.state.filter[name].from &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      pos === 'to' &&
      this.state.filter[name].from &&
      Tool.Utils.isValidDate(value, {
        after: {
          date: this.state.filter[name].from,
        },
      })
    ) {
      return
    }

    this.setState({
      [nameToString]: '',
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: undefined,
        },
      },
    })
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: value,
      },
    })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const filter = {
      ...this.state.filter,
      ngayGiaoViec: {
        from: this.state.filter.ngayGiaoViec.from
          ? moment(this.state.filter.ngayGiaoViec.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngayGiaoViec.to
          ? moment(this.state.filter.ngayGiaoViec.to).format('YYYY-MM-DD')
          : '',
      },
      hanGiaiQuyet: {
        from: this.state.filter.hanGiaiQuyet.from
          ? moment(this.state.filter.hanGiaiQuyet.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.hanGiaiQuyet.to
          ? moment(this.state.filter.hanGiaiQuyet.to).format('YYYY-MM-DD')
          : '',
      },
    }
    this.props.onSubmit && this.props.onSubmit(filter)
  }

  handleClick(e) {
    e.preventDefault()
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  filterWork(type, e) {
    e.preventDefault()
    if (type === 'quaHan') {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            quaHan: !this.state.filter.quaHan,
            ganQuaHan: false,
          },
        },
        () => {
          this.handleSubmit()
        }
      )
    } else if (type === 'ganQuaHan') {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            quaHan: false,
            ganQuaHan: !this.state.filter.ganQuaHan,
          },
        },
        () => {
          this.handleSubmit()
        }
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.workExpired !== this.props.workExpired) {
      this.setState({ workExpired: this.props.workExpired })
    }

    if (prevProps.workExpiringSoon !== this.props.workExpiringSoon) {
      this.setState({ workExpiringSoon: this.props.workExpiringSoon })
    }
    if (prevState.filter !== this.state.filter) {
      if (this.state.filter.quaHan === true) {
        this.setState({
          isActions: {
            quaHan: true,
            ganQuaHan: false,
          },
        })
      } else if (this.state.filter.ganQuaHan === true) {
        this.setState({
          isActions: {
            quaHan: false,
            ganQuaHan: true,
          },
        })
      } else if (
        this.state.filter.quaHan === false &&
        this.state.filter.ganQuaHan === false
      ) {
        this.setState({
          isActions: {
            quaHan: false,
            ganQuaHan: false,
          },
        })
      }
    }
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
  }

  render() {
    return (
      <div>
        <form
          className="search-container search-DocumentarySearch py-12 px-sm-15"
          onSubmit={this.handleSubmit}
        >
          <div className="row">
            <div className={classnames('col-xs-12 col-md-4')}>
              <div>
                <label className="search-label">
                  Thời gian (Ngày giao việc)
                </label>
                <div className="mt5 form-input-filter date-picker-inline border-radius-3">
                  <DatePicker
                    readOnly={this.props.readOnly}
                    isClearable={this.state.dateFromToString ? true : false}
                    selected={this.state.filter.ngayGiaoViec.from}
                    selectsStart
                    startDate={this.state.filter.ngayGiaoViec.from}
                    endDate={this.state.filter.ngayGiaoViec.to}
                    onChange={this.handleDateChange.bind(
                      this,
                      'ngayGiaoViec',
                      'from',
                      'dateFromToString'
                    )}
                    value={this.state.dateFromToString}
                    onChangeRaw={e =>
                      this.handleChangeRaw('dateFromToString', e.target.value)
                    }
                    onBlur={e =>
                      this.focousOut(
                        'ngayGiaoViec',
                        'from',
                        'dateFromToString',
                        e.target.value
                      )
                    }
                    format="DD/MM/YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="input-datepicker input-icon-datepicker border-transparent border-radius-3"
                    placeholderText="Từ ngày"
                    maxDate={this.state.filter.ngayGiaoViec.to}
                    popperPlacement="auto"
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: '5px, 10px',
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: 'viewport',
                      },
                    }}
                  />
                  <DatePicker
                    readOnly={this.props.readOnly}
                    isClearable={this.state.dateToToString ? true : false}
                    selected={this.state.filter.ngayGiaoViec.to}
                    selectsEnd
                    startDate={this.state.filter.ngayGiaoViec.from}
                    endDate={this.state.filter.ngayGiaoViec.to}
                    onChange={this.handleDateChange.bind(
                      this,
                      'ngayGiaoViec',
                      'to',
                      'dateToToString'
                    )}
                    value={this.state.dateToToString}
                    onChangeRaw={e =>
                      this.handleChangeRaw('dateToToString', e.target.value)
                    }
                    onBlur={e =>
                      this.focousOut(
                        'ngayGiaoViec',
                        'to',
                        'dateToToString',
                        e.target.value
                      )
                    }
                    format="DD/MM/YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="input-datepicker input-icon-datepicker border-transparent border-radius-3"
                    placeholderText="Đến ngày"
                    minDate={this.state.filter.ngayGiaoViec.from}
                    popperPlacement="auto"
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: '5px, 10px',
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: 'viewport',
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-8 mt20 mt-md-0 align-items-end">
              <div className="form-input-filter flex-auto border-radius-3 position-gridview">
                <input
                  autoFocus={false}
                  autoComplete="off"
                  style={{ paddingRight: 35 }}
                  name="textSearch"
                  className="input border-transparent pr-40 input-search-filter"
                  type="text"
                  onChange={this.handleInputChange}
                  placeholder="Tìm số cv, trích yếu (hoặc số cv:trích yếu)..."
                  value={this.state.filter.textSearch}
                />
                {this.state.filter.textSearch && (
                  <i
                    className="icon-close pt-close-gridview-search"
                    onClick={this.removeTextSearch.bind(this, 'textSearch')}
                    style={{ right: 15 }}
                  />
                )}
                <Button
                  className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
                  type="submit"
                >
                  <span className="pt-icon pt-icon-search mr0"></span>
                </Button>
              </div>
              <Button
                onClick={this.clearFilter}
                className="btn-icon flex-solid ml10 pt-intent-btn-white"
              >
                <span className="pt-icon icon-Loading mr0"></span>
              </Button>
              <Button
                type="submit"
                className="btn-icon flex-solid ml10 pt-intent-btn-white"
                onClick={this.handleClick}
              >
                <span
                  className={classnames('pt-icon mr0', {
                    'pt-icon-double-chevron-up': this.state.isOpen,
                    'pt-icon-double-chevron-down': !this.state.isOpen,
                  })}
                ></span>
              </Button>
            </div>
          </div>
          <Collapse isOpen={this.state.isOpen}>
            <div className="row">
              <div className="col-xs-12 col-md-4">
                <label className="search-label mt15">Hạn giải quyết</label>
                <div className="mt5 form-input-filter date-picker-inline border-radius-3">
                  <DatePicker
                    readOnly={this.props.readOnly}
                    isClearable={this.state.dealineFromToString ? true : false}
                    selected={this.state.filter.hanGiaiQuyet.from}
                    name="hanGiaiQuyet"
                    selectsStart
                    startDate={this.state.filter.hanGiaiQuyet.from}
                    endDate={this.state.filter.hanGiaiQuyet.to}
                    onChange={this.handleDateChange.bind(
                      this,
                      'hanGiaiQuyet',
                      'from',
                      'dealineFromToString'
                    )}
                    value={this.state.dealineFromToString}
                    onChangeRaw={e =>
                      this.handleChangeRaw(
                        'dealineFromToString',
                        e.target.value
                      )
                    }
                    onBlur={e =>
                      this.focousOut(
                        'hanGiaiQuyet',
                        'from',
                        'dealineFromToString',
                        e.target.value
                      )
                    }
                    format="DD/MM/YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="input-datepicker input-icon-datepicker border-transparent border-radius-3"
                    placeholderText="Từ ngày"
                    maxDate={this.state.filter.hanGiaiQuyet.to}
                    popperPlacement="auto"
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: '5px, 10px',
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: 'viewport',
                      },
                    }}
                  />
                  <DatePicker
                    readOnly={this.props.readOnly}
                    isClearable={this.state.dealineToToString ? true : false}
                    selected={this.state.filter.hanGiaiQuyet.to}
                    name="hanGiaiQuyet"
                    selectsEnd
                    startDate={this.state.filter.hanGiaiQuyet.from}
                    endDate={this.state.filter.hanGiaiQuyet.to}
                    onChange={this.handleDateChange.bind(
                      this,
                      'hanGiaiQuyet',
                      'to',
                      'dealineToToString'
                    )}
                    value={this.state.dealineToToString}
                    onChangeRaw={e =>
                      this.handleChangeRaw('dealineToToString', e.target.value)
                    }
                    onBlur={e =>
                      this.focousOut(
                        'hanGiaiQuyet',
                        'to',
                        'dealineToToString',
                        e.target.value
                      )
                    }
                    format="DD/MM/YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="input-datepicker input-icon-datepicker border-transparent border-radius-3"
                    placeholderText="Đến ngày"
                    minDate={this.state.filter.hanGiaiQuyet.from}
                    popperPlacement="auto"
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: '5px, 10px',
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: 'viewport',
                      },
                    }}
                  />
                </div>
              </div>
              <div className="col-xs-12 col-md-4">
                <label className="search-label mt15">Trạng thái</label>
                <div className="select mt5">
                  <select
                    name="trangThai"
                    onChange={this.handleInputChange}
                    value={this.state.filter.trangThai}
                  >
                    <option value="">Tất cả</option>
                    {this.props.status.map((item, i) => (
                      <option key={i} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-xs-12 col-md-4">
                <label className="search-label mt15">Người chủ trì</label>
                <div className="select mt5">
                  <select
                    name="nguoiDuocGiaoId"
                    onChange={this.handleInputChange}
                    value={this.state.filter.nguoiDuocGiaoId}
                  >
                    <option value="">Tất cả</option>
                    {this.props.staffs.map((item, i) => (
                      <option key={i} value={item.chucDanhId}>
                        {item.tenNhanVien}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </Collapse>
        </form>
      </div>
    )
  }
}

export default ListFilterAssigned
