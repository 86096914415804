import classnames from 'classnames'
import get from 'lodash/get'
import React from 'react'
import { Cookies, withCookies } from 'react-cookie'
import { connect } from 'react-redux'
import withRouter from 'react-router/withRouter'
import { bindActionCreators } from 'redux'

import { getNotificationCounter } from 'actions/task'
import { checkPermissionMenu, isValidCondition } from 'helpers/Helper'
import * as Actions from '../../actions'
import * as Actions2 from '../../actions/procedure'
import { fetchNotificationCounter } from 'actions/notifications'
import { getTinNhanChuaDoc } from 'actions/chat'
import { menuSidebar } from '../../constants/menuSidebar'
import * as Tool from '../../helpers'
import { Authorization, Permission } from '../auth'
import { Footer, Header, Sidebar } from '../layout'

import SidebarV2 from './SidebarV2-2'
// import { getDOToken } from 'actions/doffice'
import Navigation from './Navigation'

const cookies = new Cookies()

class MasterLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmit: true,
      isShowMenuMobile: false,
      hiddenMenuHome: false,
      enableReadOnly: this.props.readOnly,
      enableScrollFixedMenu: this.props.enableScrollFixedMenu,
      isLeftNav: false,
    }
    this.handleClick = this.handleClick.bind(this)
    this.clickSidebarMobile = this.clickSidebarMobile.bind(this)
    this.resize = this.resize.bind(this)
    this.logout = this.logout.bind(this)
    this.getMenuItems = this.getMenuItems.bind(this)
    this.handleOpenChat = this.handleOpenChat.bind(this)
  }

  checkCouterQuanLyCongViec = () => {
    const taskCounters = this.props?.counters.task
    return (
      !!taskCounters?.notification ||
      !!taskCounters?.assignToMe ||
      !!taskCounters?.watchedByMe ||
      !!taskCounters?.assignByMe
    )
  }

  getMenuItems = () => {
    const path = type => {
      if (!type) {
        return '/'
      }

      const auth = this.props?.auth
      let pathUrl = '/'
      switch (type) {
        case 'documentary':
          if (Authorization.vaild(auth.permission, [Permission.CONGVANDEN])) {
            pathUrl = '/cong-van/cong-van-den/danh-sach/chua-xu-ly'
          } else if (
            Authorization.vaild(auth.permission, [Permission.CONGVANDI])
          ) {
            pathUrl = '/cong-van/cong-van-di/danh-sach'
          } else if (
            Authorization.vaild(auth.permission, [Permission.CONGVANNOIBO])
          ) {
            pathUrl = '/cong-van/cong-van-noi-bo/danh-sach'
          } else {
            pathUrl = '/tien-ich/tim-kiem-cong-van/danh-sach'
          }

          break
        case 'signNumber':
          pathUrl = '/ky-so/cong-van-di/danh-sach'
          break
        case 'work':
          if (
            Authorization.vaild(auth.permission, [Permission.CONGVIEC_PHONGBAN])
          ) {
            pathUrl = '/cong-viec/phong-ban/danh-sach/chua-giao'
          } else if (
            Authorization.vaild(auth.permission, [Permission.CONGVIEC_CANHAN])
          ) {
            pathUrl = '/cong-viec/ca-nhan/danh-sach/chua-thuc-hien'
          } else {
            pathUrl = '/tien-ich/tim-kiem-cong-van/danh-sach'
          }

          break
        case 'utilities':
          pathUrl = Authorization.vaild(auth.permission, [Permission.ADMIN])
            ? '/tien-ich/thong-ke-cong-van/danh-sach'
            : '/tien-ich/tinh-hinh-xu-ly-cong-van/danh-sach'
          break
        case 'calendar':
          if (
            Authorization.vaild(auth.permission, [Permission.LICHHOP_QUANLY])
          ) {
            pathUrl = '/lich-hop/quan-ly-lich-hop'
          } else if (
            Authorization.vaild(auth.permission, [Permission.LICHHOP_CANHAN])
          ) {
            pathUrl = '/lich-hop/ca-nhan'
          } else if (
            Authorization.vaild(auth.permission, [Permission.LICHHOP_HAUCAN])
          ) {
            pathUrl = '/lich-hop/hau-can'
          }
          break
        case 'sidebarSystem':
          if (
            Authorization.vaild(this.props.auth.permission, [Permission.ADMIN])
          ) {
            pathUrl = '/he-thong/nguoi-dung/phan-quyen'
          } else if (
            Authorization.vaild(this.props.auth.permission, [
              Permission.HETHONG,
              Permission.DOITUONGCONGVAN_QUANLY,
            ])
          ) {
            pathUrl = '/he-thong/doi-tuong/danh-muc'
          } else if (
            Authorization.vaild(this.props.auth.permission, [
              Permission.HETHONG,
              Permission.SOLUUTRU_QUANLY,
            ])
          ) {
            pathUrl = '/he-thong/so-luu/danh-muc'
          } else if (
            Authorization.vaild(this.props.auth.permission, [
              Permission.HETHONG,
              Permission.DOKHAN_QUANLY,
            ])
          ) {
            pathUrl = '/he-thong/do-khan/danh-muc'
          } else if (
            Authorization.vaild(this.props.auth.permission, [
              Permission.HETHONG,
              Permission.DOBAOMAT_QUANLY,
            ])
          ) {
            pathUrl = '/he-thong/do-bao-mat/danh-muc'
          }

          break
        case 'notification':
          if (
            Authorization.vaild(this.props.auth.permission, [
              Permission.THONGBAO_QUANLY,
            ])
          ) {
            pathUrl = '/he-thong/thong-bao'
          } else {
            pathUrl = '/he-thong/danh-sach-thong-bao'
          }
          break
        case 'chat':
          pathUrl = '/chat'
          break
        default:
          break
      }

      return pathUrl
    }

    const status = type => {
      const numberSideBar = this.props?.numberSideBar
      const auth = this.props?.auth
      if (!numberSideBar) {
        return false
      }

      let result = false
      switch (type) {
        case 'documentary':
          const congVanChuaXuLy = Authorization.vaild(
            auth.permission,
            [Permission.CONGVANDEN],
            true
          )
            ? numberSideBar?.congVanChuaXuLy
            : 0
          const congVanBaoNham =
            Authorization.vaild(
              auth.permission,
              [Permission.CONGVANDEN_CHIDAO],
              true
            ) ||
            Authorization.vaild(
              auth.permission,
              [Permission.CONGVANDEN_DEXUATCHIDAO],
              true
            )
              ? numberSideBar?.congVanBaoNham
              : 0
          const tongSoThuHoi =
            Authorization.vaild(
              auth.permission,
              [Permission.DONGY_THUHOI],
              true
            ) &&
            Authorization.vaild(
              auth.permission,
              [Permission.DEXUAT_THUHOI],
              true
            )
              ? numberSideBar?.tongSoThuHoi
              : 0
          if (congVanChuaXuLy > 0 || congVanBaoNham > 0 || tongSoThuHoi > 0) {
            result = congVanChuaXuLy + congVanBaoNham + tongSoThuHoi
          }

          break
        case 'signNumber':
          const congVanDiCanKySo = numberSideBar?.congVanDiCanKySo ?? 0
          const congVanNoiBoCanKySo = numberSideBar?.congVanNoiBoCanKySo ?? 0
          const kySoMoRong = numberSideBar?.kySoMoRong ?? 0
          const kySoTapTrung = numberSideBar?.kySoTapTrung ?? 0
          const countYKien = numberSideBar?.countYKien ?? 0
          if (
            congVanDiCanKySo > 0 ||
            countYKien > 0 ||
            congVanNoiBoCanKySo > 0 ||
            kySoMoRong > 0 ||
            kySoTapTrung > 0
          ) {
            result =
              congVanDiCanKySo +
              countYKien +
              congVanNoiBoCanKySo +
              kySoMoRong +
              kySoTapTrung
          }

          break
        case 'work':
          const congViecChuaGiao = Authorization.vaild(
            auth.permission,
            [Permission.CONGVIEC_PHONGBAN],
            true
          )
            ? numberSideBar?.congViecChuaGiao
            : 0
          const congViecChuaThucHien = Authorization.vaild(
            auth.permission,
            [Permission.CONGVIEC_CANHAN],
            true
          )
            ? numberSideBar?.congViecChuaThucHien
            : 0
          const congViecBaoNham = Authorization.vaild(
            auth.permission,
            [Permission.CONGVIEC_PHONGBAN],
            true
          )
            ? numberSideBar?.congViecBaoNham
            : 0
          const congViecTheoDoiChiDao = Authorization.vaild(
            auth.permission,
            [Permission.CONGVIEC_CANHAN],
            true
          )
            ? numberSideBar?.theoDoiChiDaoChuaDoc
            : 0
          const congViecXemBaoCao = Authorization.vaild(
            auth.permission,
            [Permission.CONGVIEC_CANHAN],
            true
          )
            ? numberSideBar?.xemBaoCaoChuaDoc
            : 0
          const congViecXemDeBiet = Authorization.vaild(
            auth.permission,
            [Permission.CONGVIEC_CANHAN],
            true
          )
            ? numberSideBar?.xemDeBietChuaDoc
            : 0
          if (
            congViecChuaGiao > 0 ||
            congViecChuaThucHien > 0 ||
            congViecBaoNham > 0 ||
            congViecXemDeBiet > 0
          ) {
            result =
              congViecChuaGiao +
              congViecChuaThucHien +
              congViecBaoNham +
              congViecTheoDoiChiDao +
              congViecXemBaoCao +
              congViecXemDeBiet
          }
          break
        case 'documentsCabinet':
          const hscvPhongBan = Authorization.vaild(
            auth.permission,
            [Permission.HSCV_PHONGBAN_DUYET],
            true
          )
            ? numberSideBar?.hoSoCongViec?.phongBan?.[1]
            : 0
          const hscvDonVi = Authorization.vaild(
            auth.permission,
            [Permission.CONGVANDEN_QUANLY],
            true
          )
            ? numberSideBar?.hoSoCongViec?.donVi?.[2]
            : 0
          const hscvDonViDuyet = Authorization.vaild(
            auth.permission,
            [Permission.HSCV_VANPHONG_DUYET],
            true
          )
            ? numberSideBar?.hoSoCongViec?.donVi?.[3]
            : 0
          if (hscvPhongBan > 0 || hscvDonVi > 0 || hscvDonViDuyet > 0) {
            result = hscvPhongBan + hscvDonVi + hscvDonViDuyet
          }
          break
        case 'utilities':
          const phieuGuiYeuCauGopYCanXyLy =
            numberSideBar?.phieuGuiYeuCauGopYCanXyLy
          const phieuNhanYeuCauGopYCanXyLy =
            numberSideBar?.phieuNhanYeuCauGopYCanXyLy
          if (phieuGuiYeuCauGopYCanXyLy > 0 || phieuNhanYeuCauGopYCanXyLy > 0) {
            result = phieuGuiYeuCauGopYCanXyLy + phieuNhanYeuCauGopYCanXyLy
          }

          break
        case 'chat':
          if (this.props?.count) {
            result = this.props?.count
          }
          break
        case 'notification':
          if (this.props?.counterNotification) {
            result = this.props?.counterNotification
          }
          break
        case 'sidebarCalendar':
          result = this.props.counterMeeting
          break
        default:
          result = false
          break
      }

      return result
    }

    return [
      {
        path: '/',
        icon: 'icon2-home',
        name: 'Trang Chủ',
        permission: [],
        links: [],
        typeSidebar: 'HomePage',
        newStatus: false,
      },
      {
        path: path('documentary'),
        icon: 'icon2-file',
        name: 'Công Văn',
        permission: [Permission.CONGVAN],
        links: [
          '/cong-van/cong-van-den/them-moi',
          '/cong-van/cong-van-den/chinh-sua',
          '/cong-van/cong-van-den/danh-sach',
          '/cong-van/cong-van-den/danh-sach/dang-xu-ly',
          '/cong-van/cong-van-den/chi-tiet',
          '/cong-van/cong-van-di/them-moi',
          '/cong-van/cong-van-di/chinh-sua',
          '/cong-van/cong-van-di/danh-sach',
          '/cong-van/cong-van-di/chi-tiet',
          '/cong-van/cong-van-noi-bo/them-moi',
          '/cong-van/cong-van-noi-bo/chinh-sua',
          '/cong-van/cong-van-noi-bo/danh-sach',
          '/cong-van/cong-van-noi-bo/chi-tiet',
          '/cong-van/chuyen-nham/danh-sach',
          // '/cong-van/cong-van-evn/danh-sach',
          '/cong-van/uy-quyen',
          '/cong-van-den/chi-dao',
          '/cong-van/cong-van-di-thu-hoi/danh-sach',
          '/cong-van/cong-van-di-thu-hoi/chi-tiet',
          '/cong-van/cong-van-den-thu-hoi/danh-sach',
          '/tien-ich/tim-kiem-cong-van-v61/danh-sach',
          // '/quan-ly-cong-viec-do/tim-kiem-cong-van',
          '/tien-ich/tim-kiem-cong-van/danh-sach',
        ],
        typeSidebar: 'documentary',
        newStatus: status('documentary'),
      },
      {
        path: path('work'),
        icon: 'icon2-work',
        name: 'Công Việc',
        permission: [Permission.CONGVIEC],
        links: [
          '/cong-viec/giao-viec-moi',
          '/cong-viec/them-moi-giao-viec',
          '/cong-viec/phong-ban/danh-sach/da-giao',
          '/cong-viec/ca-nhan/danh-sach',
          '/cong-viec/ca-nhan/chi-tiet/chua-thuc-hien',
          '/cong-viec/ca-nhan/chi-tiet/dang-thuc-hien',
          '/cong-viec/ca-nhan/chi-tiet/da-thuc-hien',
          '/cong-viec/ca-nhan/xem-de-biet',
          '/cong-viec/phong-ban/chi-tiet/chua-giao',
          '/cong-viec/phong-ban/chi-tiet/da-giao',
          '/cong-viec/uy-quyen',
          '/cong-viec/chuyen-nham/danh-sach',
          '/cong-viec/ca-nhan/danh-sach/da-giao',
          '/cong-viec/ca-nhan/chi-tiet/da-giao',
          '/cong-viec/ca-nhan/xem-bao-cao',
          '/cong-viec/ca-nhan/theo-doi-chi-dao',
        ],
        typeSidebar: 'work',
        newStatus: status('work'),
      },
      {
        path: path('signNumber'),
        icon: 'icon2-file-protect',
        name: 'Ký Số',
        permission: [Permission.KYSO_QUANLY],
        links: [
          '/ky-so/cong-van-di/them-moi',
          '/ky-so/cong-van-di/danh-sach',
          '/ky-so/cong-van-di/chi-tiet',
          '/ky-so/cong-van-di/chinh-sua',
          '/ky-so/cong-van-noi-bo/them-moi',
          '/ky-so/cong-van-noi-bo/danh-sach',
          '/ky-so/cong-van-noi-bo/chi-tiet',
          '/ky-so/cong-van-noi-bo/chinh-sua',
          '/ky-so/cong-van-dau-thau/them-moi',
          '/ky-so/du-an/them-moi',
          '/ky-so/du-an/chinh-sua',
          '/ky-so/du-an/danh-sach',
          '/ky-so/du-an/chi-tiet',
          '/ky-so-mo-rong/du-an/danh-sach',
          '/ky-so-mo-rong/du-an/them-moi',
          '/ky-so-mo-rong/du-an/chinh-sua',
          '/ky-so-mo-rong/du-an/thong-tin',
          '/ky-so-tap-trung/van-ban-ky-duyet',
          '/ky-so-tap-trung/tim-kiem-van-ban',
          '/hoi-dong-thanh-vien/lay-y-kien',
          '/hoi-dong-thanh-vien/lay-y-kien/danh-sach',
        ],
        typeSidebar: 'signNumber',
        newStatus: status('signNumber'),
      },
      {
        path: path('notification'),
        icon: 'icon2-megaphone',
        name: 'Thông báo',
        permission: [],
        links: [
          '/he-thong/thong-bao',
          '/he-thong/thong-bao/them-moi',
          '/he-thong/thong-bao/chinh-sua',
          '/he-thong/thong-bao/chi-tiet',
          '/he-thong/danh-sach-thong-bao',
          '/he-thong/quan-ly-cong-viec/banner/danh-sach',
          '/he-thong/quan-ly-cong-viec/banner/them-moi',
          '/he-thong/quan-ly-cong-viec/banner/chinh-sua',
        ],
        typeSidebar: 'notification',
        newStatus: status('notification'),
      },
      // {
      //   typeSidebar: 'taskManager',
      //   path: Authorization.vaild(this.props.auth.permission, [
      //     Permission.CONGVANDEN_CHIDAO,
      //   ])
      //     ? '/quan-ly-cong-viec/toi-giao'
      //     : '/quan-ly-cong-viec/duoc-giao',
      //   icon: 'icon-case',
      //   name: 'Quản lý công việc',
      //   permission: [Permission.SUDUNG_QLCV],
      //   links: [
      //     '/quan-ly-cong-viec/thong-bao/',
      //     '/quan-ly-cong-viec/toi-giao',
      //     '/quan-ly-cong-viec/duoc-giao',
      //     '/quan-ly-cong-viec/theo-doi',
      //     '/quan-ly-cong-viec/tags',
      //     '/quan-ly-cong-viec/nhom-thuong-giao-viec',
      //     '/quan-ly-cong-viec/thong-ke',
      //     '/quan-ly-cong-viec-do/tim-kiem-cong-van',
      //     '/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop',
      //     '/thong-bao-ket-luan-cuoc-hop/thong-ke',
      //     '/thong-bao-ket-luan-cuoc-hop/chi-tiet',
      //   ],
      //   newStatus: this.checkCouterQuanLyCongViec(),
      // },
      {
        path: path('chat'),
        icon: 'icon2-chat',
        name: 'Tin nhắn',
        permission: [Permission.SUDUNG_CHAT],
        links: ['/chat'],
        typeSidebar: 'chat',
        newStatus: status('chat'),
      },
      {
        path: path('calendar'),
        icon: 'icon2-date',
        name: 'Lịch họp',
        orPermission: [
          Permission.LICHHOP_CANHAN,
          Permission.LICHHOP_HAUCAN,
          Permission.LICHHOP_QUANLY,
        ],
        links: [
          '/lich-hop/quan-ly-lich-hop',
          '/lich-hop/danh-muc/loai-cuoc-hop',
          '/lich-hop/danh-muc/loai-cuoc-hop/them-moi',
          '/lich-hop/danh-muc/loai-cuoc-hop/chinh-sua',
          '/lich-hop/danh-muc/cap-do-cuoc-hop',
          '/lich-hop/danh-muc/cap-do-cuoc-hop/them-moi',
          '/lich-hop/danh-muc/cap-do-cuoc-hop/chinh-sua',
          '/lich-hop/danh-muc/dia-diem-hop',
          '/lich-hop/danh-muc/dia-diem-hop/them-moi',
          '/lich-hop/danh-muc/dia-diem-hop/chinh-sua',
          '/lich-hop/danh-muc/nhom-vat-tu',
          '/lich-hop/danh-muc/nhom-vat-tu/them-moi',
          '/lich-hop/danh-muc/nhom-vat-tu/chinh-sua',
          '/lich-hop/danh-muc/loai-vat-tu',
          '/lich-hop/danh-muc/loai-vat-tu/them-moi',
          '/lich-hop/danh-muc/loai-vat-tu/chinh-sua',
          '/lich-hop/quan-ly-lich-hop',
          '/lich-hop/quan-ly-lich-hop/them-moi',
          '/lich-hop/quan-ly-lich-hop/chinh-sua',
          '/lich-hop/quan-ly-lich-hop/chi-tiet',
          '/lich-hop/chi-tiet-nguoi-tham-gia',
          '/lich-hop/hau-can/cap-nhat',
          '/lich-hop/hau-can/chi-tiet',
          '/lich-hop/ca-nhan',
          '/lich-hop/hau-can',
        ],
        typeSidebar: 'sidebarCalendar',
        newStatus: status('sidebarCalendar'),
      },
      {
        path: '/qlcv',
        icon: 'icon-case',
        name: 'Quản lý công việc',
        permission: [Permission.SUDUNG_QLCV],
        links: ['/qlcv'],
        typeSidebar: 'chat',
      },
      {
        path: '/tu-tai-lieu/danh-sach',
        icon: 'icon2-folder',
        name: 'Tủ Tài Liệu',
        permission: [Permission.TUTAILIEU],
        links: [
          '/tu-tai-lieu/danh-sach',
          '/tu-tai-lieu/them-file',
          '/tu-tai-lieu/di-chuyen',
          '/tu-tai-lieu/di-chuyen-ngan-tu',
          '/tu-tai-lieu/chi-tiet',
          '/ho-so-cong-viec/chi-tiet',
        ],
        typeSidebar: 'documentsCabinet',
        newStatus: status('documentsCabinet'),
      },
      {
        path: path('utilities'),
        icon: 'icon2-utilities',
        name: 'Tiện ích',
        permission: [],
        links: [
          '/tien-ich/thong-ke-cong-van/danh-sach',
          '/tien-ich/tinh-hinh-xu-ly-cong-van/danh-sach',
          '/tien-ich/thong-ke-cong-viec/danh-sach',
          '/tien-ich/thong-ke-tu-tai-lieu/danh-sach',
          '/tien-ich/thong-ke-ho-so-cong-viec/danh-sach',
          '/tien-ich/thong-ke-ky-so/danh-sach',
          '/tien-ich/thong-ke-van-ban-nhan-dang/danh-sach',
          '/tien-ich/thong-ke-thu-hoi-cong-van/danh-sach',
          '/tien-ich/thong-ke-bao-nham/danh-sach',
          '/tien-ich/thong-ke-nhan-vien/danh-sach',
          '/tien-ich/thong-ke-tin-nhan/danh-sach',
          '/danh-ba',
          '/tien-ich/lay-y-kien-don-vi/danh-sach-goi',
          '/tien-ich/lay-y-kien-don-vi/danh-sach-nhan',
          '/tien-ich/lay-y-kien-don-vi/tao-phieu',
        ],
        typeSidebar: 'utilities',
        newStatus: status('utilities'),
      },
      {
        path: path('sidebarSystem'),
        icon: 'icon2-computer',
        name: 'Hệ thống',
        orPermission: [
          Permission.ADMIN,
          Permission.SUPERADMIN,
          Permission.HETHONG,
        ],
        links: [
          '/he-thong/nguoi-dung/phong-ban',
          '/he-thong/nguoi-dung/phan-quyen',
          '/he-thong/nguoi-dung/them-moi',
          '/he-thong/nguoi-dung/chinh-sua',
          '/he-thong/phan-quyen-vai-tro',
          '/he-thong/vai-tro/danh-muc',
          '/he-thong/vai-tro/them-moi',
          '/he-thong/vai-tro/chinh-sua',
          '/he-thong/don-vi/danh-muc',
          '/he-thong/don-vi/them-moi',
          '/he-thong/don-vi/chinh-sua',
          '/he-thong/phong-ban/danh-muc',
          '/he-thong/phong-ban/them-moi',
          '/he-thong/phong-ban/chinh-sua',
          '/he-thong/chuc-vu/danh-muc',
          '/he-thong/chuc-vu/them-moi',
          '/he-thong/chuc-vu/chinh-sua',
          '/he-thong/so-luu/danh-muc',
          '/he-thong/so-luu/them-moi',
          '/he-thong/so-luu/chinh-sua',
          '/he-thong/doi-tuong/danh-muc',
          '/he-thong/doi-tuong/them-moi',
          '/he-thong/doi-tuong/chinh-sua',
          '/he-thong/do-khan/danh-muc',
          '/he-thong/do-khan/them-moi',
          '/he-thong/do-khan/chinh-sua',
          '/he-thong/do-bao-mat/danh-muc',
          '/he-thong/do-bao-mat/them-moi',
          '/he-thong/do-bao-mat/chinh-sua',
          '/he-thong/service/them-moi',
          '/he-thong/service/chinh-sua',
          '/he-thong/service/danh-sach',
          '/he-thong/service/thong-ke',
          '/he-thong/danh-muc-quy-trinh',
          '/he-thong/quy-trinh',
          '/he-thong/don-vi',
          '/he-thong/thong-ke-ky-so-tap-trung/danh-sach',
          '/he-thong/phuong-phap-luu/danh-sach',
          '/he-thong/phuong-phap-luu/them-moi',
          '/he-thong/phuong-phap-luu/chinh-sua',
          '/he-thong/phien-ban/phan-quyen-chuc-nang',
          '/he-thong/quan-ly-cong-viec/do-uu-tien/danh-sach',
        ],
        typeSidebar: 'sidebarSystem',
        newStatus: false,
      },
    ]
  }

  handleClick(e) {
    this.setState(
      {
        isSubmit: !this.state.isSubmit,
      },
      () => {
        this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        cookies.set('sidebarCollapsed', this.state.isSubmit)
      }
    )
  }

  clickSidebarMobile(e) {
    this.setState({
      isShowMenuMobile: !this.state.isShowMenuMobile,
    })
  }
  logout(e) {
    let checkin_maxacnhan = localStorage.getItem('checkin_maxacnhan')
    let user_login = localStorage.getItem('user_login')
    localStorage.clear()
    localStorage.setItem('user_login', user_login)
    localStorage.setItem('checkin_maxacnhan', checkin_maxacnhan)
    sessionStorage.clear()
    this.props.actions.authLogout()
  }

  componentWillMount() {
    window.addEventListener('resize', this.resize)
    window.addEventListener('checkReloadPage', this.checkReloadPage)
    this.resize()
    this.checkReloadPage()

    const collapsed = cookies.get('sidebarCollapsed')
    if (this.props.typeSidebar === 'taskManager') {
      this.setState({ isSubmit: collapsed ? collapsed === 'true' : true })
    }
  }

  checkReloadPage = () => {
    window.onbeforeunload = e => {
      Tool.Utils.saveData('isReloadPage', true)
    }

    window.onload = e => {
      Tool.Utils.saveData('isReloadPage', false)
    }
  }

  resize() {
    if (this.props.collapseSideBar && this.props.collapseSideBar === true) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (window.innerWidth <= 1024) {
      this.setState(
        {
          enableReadOnly: true,
        },
        () => {
          this.props.actions.commonReadOnlyDatePicker(this.state.enableReadOnly)
        }
      )
    }

    if (
      this.props.location.pathname.indexOf('them-file') > -1 ||
      this.props.location.pathname.indexOf('di-chuyen') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (
      window.innerWidth <= 1024 &&
      this.props.location.pathname.indexOf('danh-sach') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (
      window.innerWidth <= 1024 &&
      this.props.location.pathname.indexOf('chuyen-nham') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (
      window.innerWidth <= 1024 &&
      this.props.location.pathname.indexOf('tien-ich') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (
      window.innerWidth <= 1024 &&
      this.props.location.pathname.indexOf('danh-ba') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (
      window.innerWidth <= 1024 &&
      this.props.location.pathname.indexOf('he-thong') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }
  }

  componentDidMount() {
    const { auth } = this.props
    const currentUserPermission = auth.permission
    getNotificationCounter()
    if (
      isValidCondition(currentUserPermission, [Permission.SUDUNG_QLCV], 'OR')
    ) {
    }
    this.props.actions.getNumberHomePage()
    if (this.props.typeSidebar === 'listContact') {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }
    if (this.props.location.pathname === '/error-page') {
      document.body.style.backgroundColor = 'white'
    }
    const danhSach = this.props.match.path.indexOf('danh-sach') > -1
    if (this.props.typeSidebar === 'documentary' && danhSach) {
      this.props.actions.getNumberDocumentPage()
      // this.props.actions.getNumberEVNDocument()
      this.props.actions.getTongSoCongVanThuHoi()
    }
    if (this.props.typeSidebar === 'signNumber' && danhSach) {
      this.props.actions.getNumberSignNumberPage()
      this.props.actions.getNumberProjectSignNumberPage()
      this.props.actions.getNumberPhieuYKien()
    }
    if (this.props.typeSidebar === 'work' && danhSach) {
      if (this.props.config.refresh) {
        return this.props.actions.getNumberWorkPage(this.props.config.refresh)
      }
      this.props.actions.getNumberWorkPage()
    }
    if (
      this.props.typeSidebar === 'documentsCabinet' ||
      danhSach ||
      this.props.location.pathname === '/'
    ) {
      this.props.actions.getNumberHSCV()
    }

    this.props.actions.fetchNotificationCounter()
    this.props.actions.getTinNhanChuaDoc()
    this.props.actions.getMeetingCounter()
  }

  componentDidUpdate(prevProps, prevState) {
    const danhSach = this.props.match.path.indexOf('danh-sach') > -1
    if (
      prevProps.typeSidebar === 'documentary' &&
      danhSach &&
      prevProps.match.url !== this.props.match.url
    ) {
      this.props.actions.getNumberDocumentPage()
      // this.props.actions.getNumberEVNDocument()
    }
    if (
      prevProps.typeSidebar === 'work' &&
      danhSach &&
      prevProps.match.url !== this.props.match.url
    ) {
      if (this.props.config.refresh) {
        return this.props.actions.getNumberWorkPage(this.props.config.refresh)
      }
      this.props.actions.getNumberWorkPage()
    }
  }

  UNSAFE_componentWillReceiveProps() {
    if (cookies.get('theme_menu') && cookies.get('theme_menu') === 'VERTICAL') {
      this.setState({ isLeftNav: true })
    } else {
      this.setState({ isLeftNav: false })
    }
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null
    window.removeEventListener('resize', this.resize)
    window.removeEventListener('checkReloadPage', this.checkReloadPage)
  }

  handleOpenChat() {
    // const { auth } = this.props

    // const url =
    //   Types.FE_CHAT_URL +
    //   `?accessToken=${auth.token.accessToken}&expiresIn=${auth.token.expiresIn}&tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}`
    // window.open(url, 'eoffice-chat')
    this.props.history.push('/chat')
  }

  render() {
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {})
    )
    const isLeftNav = this.state?.isLeftNav
    const {
      auth,
      newLayout,
      children,
      typeSidebar,
      numberSideBar,
      location,
      quyensMenu,
    } = this.props
    return (
      <div>
        {isLeftNav && (
          <Navigation
            quyensMenu={quyensMenu}
            user={auth.user}
            menu={this.getMenuItems()}
          />
        )}
        <div
          className={classnames('page-container', {
            'is-navigation': isLeftNav,
            'page-container-dashboard': location.pathname === '/',
            'sidebar-collapsed': !this.state.isSubmit,
            'sidebar-collapsed-back': this.state.isSubmit,
            'page-container-chat': typeSidebar === 'Chat',
          })}
        >
          {(!isLeftNav || (isLeftNav && location.pathname !== '/')) && (
            <Header
              quyensMenu={quyensMenu}
              user={this.props.auth.user}
              roleId={this.props.auth && this.props.auth.roleId}
              path={this.props.path}
              actions={this.props.actions}
              hiddenMenuHome={this.state.hiddenMenuHome}
              history={this.props.history}
              isNavigation={isLeftNav}
              menu={this.getMenuItems()}
              onHandleClick={this.clickSidebarMobile}
              onOpenChat={this.handleOpenChat}
              handleLogout={this.logout}
            />
          )}
          {typeSidebar && typeSidebar === 'taskManager' && (
            <SidebarV2
              menu={menuSidebar.taskManager}
              isMobileMenuShown={this.state.isShowMenuMobile}
              isMenuShown={this.state.isSubmit}
              isNavigation={isLeftNav}
              toggleMenu={this.handleClick}
              closeMenuMobile={this.clickSidebarMobile}
            />
          )}
          {typeSidebar &&
            typeSidebar !== 'taskManager' &&
            typeSidebar !== 'HomePage' &&
            typeSidebar !== 'documentsCabinet' && (
              <Sidebar
                auth={auth}
                quyensMenu={quyensMenu}
                data={
                  typeSidebar === 'notification'
                    ? menuSidebar?.notification
                    : typeSidebar === 'sidebarCalendar'
                    ? menuSidebar.calendar
                    : typeSidebar === 'documentary'
                    ? menuSidebar.documentary
                    : typeSidebar === 'work'
                    ? menuSidebar.work
                    : typeSidebar === 'signNumber'
                    ? menuSidebar.signNumber
                    : typeSidebar === 'documentsCabinet'
                    ? checkPermissionMenu(this.props?.quyensMenu || [], [
                        Permission.MENU_SIDEBAR.SMENU_TTL,
                      ])
                      ? menuSidebar.documentsCabinet
                      : menuSidebar.utilities
                    : typeSidebar === 'sidebarSystem'
                    ? menuSidebar.system
                    : typeSidebar === 'utilities'
                    ? menuSidebar.utilities
                    : null
                }
                isSubmit={this.state.isSubmit}
                isShowMenuMobile={this.state.isShowMenuMobile}
                notAssign={
                  numberSideBar
                    ? typeSidebar === 'work'
                      ? numberSideBar.congViecChuaGiao
                      : null
                    : null
                }
                inProcess={
                  numberSideBar
                    ? typeSidebar === 'work'
                      ? numberSideBar.congViecDangThucHien
                      : numberSideBar.congVanDangXuLy
                    : 0
                }
                noProcess={
                  numberSideBar
                    ? typeSidebar === 'work'
                      ? numberSideBar.congViecChuaThucHien
                      : numberSideBar.congVanChuaXuLy
                    : 0
                }
                alertMistake={
                  numberSideBar
                    ? typeSidebar === 'work'
                      ? numberSideBar.congViecBaoNham
                      : numberSideBar.congVanBaoNham
                    : 0
                }
                followDirection={
                  numberSideBar
                    ? typeSidebar === 'work'
                      ? numberSideBar.theoDoiChiDaoChuaDoc
                      : null
                    : null
                }
                viewReport={
                  numberSideBar
                    ? typeSidebar === 'work'
                      ? numberSideBar.xemBaoCaoChuaDoc
                      : null
                    : null
                }
                seeToKnow={
                  numberSideBar
                    ? typeSidebar === 'work'
                      ? numberSideBar.xemDeBietChuaDoc
                      : null
                    : null
                }
                parentUnitId={this.props.auth && this.props.auth.parentUnitId}
                evnTotal={numberSideBar ? numberSideBar.congVanEvn : 0}
                tongSoThuHoi={numberSideBar ? numberSideBar.tongSoThuHoi : 0}
                sendTotal={
                  numberSideBar && typeSidebar === 'signNumber'
                    ? numberSideBar.congVanDiCanKySo
                    : null
                }
                internalTotal={
                  numberSideBar && typeSidebar === 'signNumber'
                    ? numberSideBar?.congVanNoiBoCanKySo || 0
                    : null
                }
                projectTotal={
                  numberSideBar && typeSidebar === 'signNumber'
                    ? numberSideBar?.kySoMoRong || 0
                    : null
                }
                kySoTapTrungTotal={
                  numberSideBar && typeSidebar === 'signNumber'
                    ? numberSideBar?.kySoTapTrung || 0
                    : null
                }
                yKienTotal={
                  numberSideBar && typeSidebar === 'signNumber'
                    ? numberSideBar?.countYKien || 0
                    : null
                }
                phieuGuiYeuCauGopYCanXyLy={
                  numberSideBar && typeSidebar === 'utilities'
                    ? numberSideBar?.phieuGuiYeuCauGopYCanXyLy || 0
                    : null
                }
                phieuNhanYeuCauGopYCanXyLy={
                  numberSideBar && typeSidebar === 'utilities'
                    ? numberSideBar?.phieuNhanYeuCauGopYCanXyLy || 0
                    : null
                }
                counterNotification={this.props.counterNotification}
                dataCounter={{
                  lichHop: {
                    caNhan: this.props.counterMeeting,
                  },
                }}
                isNavigation={isLeftNav}
                onHandleClick={this.handleClick}
                clickSidebarMobile={this.clickSidebarMobile}
              />
            )}
          <main
            className={classnames({
              'left-content':
                !newLayout &&
                typeSidebar !== 'documentsCabinet' &&
                typeSidebar !== 'Chat',
              'right-content-wrapper': newLayout,
              'dashboard-page-wapper':
                location.pathname === '/' ||
                // giữ class với 1 số router '/thong-bao/*'
                get(location, 'pathname', '').split('/')[1] === 'thong-bao',
              'dashboard-page-error': location.pathname === '/error-page',
            })}
          >
            {!newLayout && (
              <div
                className={classnames({
                  'content-wrapper padding-mobile-default':
                    location.pathname !== '/',
                })}
              >
                {childrenWithProps}
              </div>
            )}
            {newLayout && (
              <div
                className="task-manager-wrapper"
                style={{
                  display: 'flex',
                  minHeight: '100vh',
                  height: 'auto',
                  background: `#fff url(${
                    get(cookies.get('background_image'), 'url')
                      ? `/backgrounds/${get(
                          cookies.get('background_image'),
                          'url'
                        )}`
                      : '/backgrounds/bg1.jpg'
                  }) center/cover no-repeat`,
                }}
              >
                {children}
              </div>
            )}

            <Footer />
          </main>
        </div>
      </div>
    )
  }
}

MasterLayout.defaultProps = {
  typeSidebar: '',
  collapseSideBar: false,
}

const mapStateToProps = state => ({
  counters: state?.counters,
  config: state.common.config,
  userName: state.auth.user.username,
  auth: state.auth,
  receiveDocumentTotals: state.common.receiveDocumentTotals,
  departmentWorkTotals: state.common.departmentWorkTotals,
  personalWorkTotals: state.common.personalWorkTotals,
  numberSideBar: state.common.numberSideBar,
  totalSeeToKnow: state.personalWorks.totalSeeToKnowNotRead,
  alertMistakeTotals: state.common.alertMistakeTotals,
  readOnly: state.common.enableReadOnly,
  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
  quyensMenu: state.common.quyensMenu,
  count: state.chats.count,
  counterNotification: state.notifications.counter,
  counterMeeting: state.lichHop.counterMeeting,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { ...Actions, ...Actions2, fetchNotificationCounter, getTinNhanChuaDoc },
    dispatch
  ),
})

export default withCookies(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MasterLayout))
)
